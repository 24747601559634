import {
  PRICE_RANGE,
  PRICE_RANGE_FRENCH_LOCALE,
  PriceRangeFrench,
} from "@/constants/index"
import { SPACE_REGEX } from "@/constants/regex"
import { formatFacetName, retainSearchQueryParams } from "@/utils/helper"
const setUrlParams = (
  filters,
  isReplace,
  type,
  facetLabels,
  resetTitle,
  clearAllFilters = () => {}
) => {
  let query = window.location.search
  if (query.length && !query.includes("tab") && type)
    query += isReplace ? "" : `${query.includes("?") ? "&" : "?"}tab=${type}`
  if (filters.length > 0) {
    query += query ? "&facets=" : ""
    filters.forEach((filter, i) => {
      const values = [...filter.value]
      const searches = location.search.split("&")
      const index = searches.findIndex(search => search.includes(filter.facet))
      if (index > -1) {
        searches.splice(index, 1)
        query = searches.join("&")
        query += query ? "&" : ""
      }
      if (
        values.length !== 0 && filter.facet === "Price_Range"
          ? true
          : facetLabels[filter.facet?.replace(/^\*{2}/, "")]
      ) {
        query += `${
          filter.facet === "Price_Range"
            ? filter.facet
            : facetLabels[filter.facet?.replace(/^\*{2}/, "")]?.replace(
                / /g,
                "_"
              ) ?? filter.facet
        }%3A${
          filter.facet === "Price_Range"
            ? filter.value.join("%7C")
            : encodeURIComponent(values.join("|"))
        }${i !== filters.length - 1 ? "%2C" : ""}`
      }
    })
    if (resetTitle) resetTitle()
  } else {
    clearAllFilters(false)
  }
  const queryStr = query.toString()
  const queryParam =
    queryStr.length === 0
      ? window.location.pathname
      : queryStr.includes("?")
      ? queryStr
      : `?${queryStr}`
  query &&
    window.history.replaceState(
      window.location.pathname,
      document.title,
      queryParam.includes("facets")
        ? `?${queryParam.replace("?", "")}`
        : `?facets=${queryParam.replace("?", "")}`
    )
  if (type) retainSearchQueryParams(type)
}

const getAppliedFiltersForAnalytics = (appliedFilters, facetLabels) => {
  const filterArray = []
  appliedFilters.forEach(filter => {
    filter.display.forEach(filtervalue => {
      filterArray.push(
        `${formatFacetName(filter.facet, null, facetLabels)}:${filtervalue}`
      )
    })
  })
  return filterArray.length ? filterArray.join("|") : ""
}

const isFilterSelected = (appliedFilterFacet, currentFilter) => {
  return (
    appliedFilterFacet?.display?.includes(currentFilter.display) ||
    appliedFilterFacet?.display?.some(
      item =>
        item.replace(SPACE_REGEX, "") ===
        currentFilter?.display?.replace(SPACE_REGEX, "")
    )
  )
}

const isPriceRangeFilter = filterFacet => {
  return (
    filterFacet &&
    (filterFacet === PRICE_RANGE ||
      filterFacet === PriceRangeFrench ||
      filterFacet === PRICE_RANGE_FRENCH_LOCALE)
  )
}

const getSelectedFacet = (appliedFilters, currentFilter) => {
  return appliedFilters.find(appliedFilter => {
    if (appliedFilter.facet === currentFilter.facet) {
      return true
    } else {
      return false
    }
  })
}

const isCollapsibleOpen = (facet, collapse) => {
  return isPriceRangeFilter(facet)
    ? Object.keys(collapse)?.some(
        appliedFacet =>
          isPriceRangeFilter(appliedFacet) && collapse[appliedFacet]
      )
    : collapse[facet]
}

export {
  setUrlParams,
  getAppliedFiltersForAnalytics,
  isFilterSelected,
  isPriceRangeFilter,
  getSelectedFacet,
  isCollapsibleOpen,
}
